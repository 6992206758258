<template>
  <div class="container mt-3">
    <div class="mt-3">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link to="/"><i class="fas fa-home"></i></router-link>
          </li>
          <li class="breadcrumb-item active">Kursus</li>
        </ol>
      </nav>
    </div>

    <div class="container-box mt-3">
      <div class="header-box">
        <div class="row mx-0">
          <div class="col-8">
            <div class="box-left">
              <!-- <h2>Selamat kembali, {firstname}</h2> -->
              <h2>Selamat kembali, {{ this.currentUser.full_name }}.</h2>
              <p>
                Selamat kembali ke bahagian kursus. Sambung semula sesi
                pembelajaran anda untuk memperoleh sijil untuk kursus - kursus
                ini.
              </p>
            </div>
          </div>
          <!-- <div class="col-4">
                    <div class="box-right"></div>
                </div> -->
        </div>
      </div>

      <div class="contents mt-3">
        <ul class="nav nav-tabs">
          <li class="nav-item">
            <a
              href="#"
              class="nav-link"
              :class="{ active: currentActiveNav == 1 }"
              @click="currentActiveNav = 1"
              >Utama</a
            >
          </li>
          <li class="nav-item">
            <a
              href="#"
              class="nav-link"
              :class="{ active: currentActiveNav == 2 }"
              @click="currentActiveNav = 2"
              >Dalam proses</a
            >
          </li>
          <li class="nav-item">
            <a
              href="#"
              class="nav-link"
              :class="{ active: currentActiveNav == 3 }"
              @click="currentActiveNav = 3"
              >Selesai</a
            >
          </li>
        </ul>

        <div v-if="currentActiveNav != 4" class="nav-contents mt-3">
          <div class="row mx-0 g-2">
            <div
              class="col-12"
              v-for="course in filteredCourses"
              :key="course.id"
            >
              <div
                class="course-box position-relative"
                :class="{ 'course-box-blue': !hasCourse(course) }"
                @click="goto(`/user/courses/${course.id}`, course)"
              >
                <div class="box-featured-img" :style="courseBoxBg()"></div>
                <div class="course-box-inner position-relative p-3">
                  <div class="row mx-0">
                    <div class="col-6 col-sm-8 col-md-10">
                      <h5>{{ course.attributes.course_name }}</h5>
                    </div>
                    <div class="col-6 col-sm-4 col-md-2">
                      <div
                        class="d-flex justify-content-end align-items-center h-100"
                      >
                        <progress-text-comp
                          v-if="hasCourse(course)"
                          :percentage="courseProgress(course)"
                          type="progress"
                        />
                        <span v-else class="span-click"
                          >Klik untuk mula <i class="fas fa-arrow-right"></i
                        ></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="filteredCourses.length <= 0" class="nav-contents mt-3">
              <i>Tiada</i>
            </div>
          </div>
        </div>
        <div v-else class="nav-contents mt-3">
          <i>Tiada notifikasi untuk setakat ini.</i>
        </div>
      </div>
    </div>
    <loading-spinner v-if="isUpdating" />
  </div>
</template>

<script>
import qs from "qs";
import API from "../../utils/API";
import ProgressCircleComp from "./../../components/ProgressCircle.vue";
import ProgressTextComp from "./../../components/ProgressText.vue";
import { mapActions, mapGetters } from "vuex";
import LoadingSpinner from "@/components/LoadingSpinner.vue";

export default {
  components: {
    ProgressCircleComp,
    ProgressTextComp,
    LoadingSpinner,
  },
  data() {
    return {
      courses: [],
      filteredCourses: [],
      userCourses: [],
      isUpdating: false,
      userCourseCompletions: [],
      currentActiveNav: 1, //1 - Utama, 2- Dalam Progress, 3 - Selesai, 4- Notifikasi,
    };
  },
  computed: {
    ...mapGetters(["currentUser", "currentUserAccessType"]),
  },
  watch: {
    currentActiveNav(_val) {
      let ids = [];
      switch (_val) {
        case 1: //load all
          this.filteredCourses = this.courses;
          break;
        case 2: //load any progress
          this.userCourseCompletions.forEach((i) => {
            if (i.completion_percentage < 100) ids.push(i.course.id);
          });
          this.filteredCourses = this.courses.filter((i) => ids.includes(i.id));
          break;
        case 3: //load 100%
          this.userCourseCompletions.forEach((i) => {
            if (i.completion_percentage == 100) ids.push(i.course.id);
          });
          this.filteredCourses = this.courses.filter((i) => ids.includes(i.id));
          break;
      }
    },
  },
  methods: {
    async goto(_path, _course) {
      this.isUpdating = true;

      try {
        await this.changeCourseProgress(_course);
        this.$router.push(_path);
        this.isUpdating = false;
      } catch (error) {
        console.error("An error occurred:", error);
        this.isUpdating = false;
        window.location.reload();
      }
    },
    courseBoxBg() {
      let randomNumber = Math.ceil(Math.random() * 4);
      return `
                background-image: url(/img/random/featured-img-${randomNumber}.jpg);
                background-size: cover;
                background-repeat: no-repeat;
                `;
    },
    hasCourse(_course) {
      if (this.userCourseCompletions.length > 0) {
        let found = this.userCourseCompletions.find(
          (i) => i.course.id == _course.id
        );
        if (found) return true;
      }
      return false;
    },
    courseProgress(_course) {
      if (this.userCourseCompletions.length > 0) {
        let courseProgress = this.userCourseCompletions.find(
          (i) => i.course.id == _course.id
        );
        if (courseProgress) return courseProgress.completion_percentage;
      }
      return false;
    },
    async changeCourseProgress(_course) {
      return new Promise(async (resolve, reject) => {
        try{
          const res02 = await API.get(
            `users/${this.currentUser.id}?populate[user_course_completions][populate][0]=course&populate=courses`
          );

          this.userCourses = res02.data.courses;
          this.userCourseCompletions = res02.data.user_course_completions;
          //filter only get the course that is not null.
          this.userCourseCompletions = this.userCourseCompletions.filter(
            (i) => i.course !== null
          );

          let found =
            this.userCourseCompletions.length > 0
              ? this.userCourseCompletions.find((i) => i.course.id == _course.id)
              : null;
          if (!found) {
            this.userCourses.push(_course);
            this.userCourseCompletions.push({
              course: _course,
              completion_percentage: 0,
            });
          }

          await API.put(`users/${this.currentUser.id}`, {
            courses: this.userCourses,
            user_course_completions: this.userCourseCompletions,
          });

          resolve();
        }catch(error){
          reject();
        }
      })
    },
  },
  async mounted() {
    this.isUpdating = true;

    try{
      let userAccessTypesFilter;
      if (!this.currentUserAccessType)
        userAccessTypesFilter = { name: { $eq: "public" } };
      else userAccessTypesFilter = { id: { $eq: this.currentUserAccessType.id } };

      const queryParams = qs.stringify(
        {
          filters: {
            user_access_types: userAccessTypesFilter,
            is_active: {
              $eq: true,
            },
            course_type: {
              $eq: "online",
            },
          },
        },
        {
          encodeValuesOnly: true,
        }
      );
      let url = `courses?${queryParams}`;

      const res = await API.get(url);

      // const res = await API.get(
      //   "courses?filters[course_type]=online&populate=%2A"
      // );

      this.courses = res.data.data;

      //sort courses
      this.courses = this.courses.sort((itemA, itemB) => {
        let x = itemA.attributes.course_name.toLowerCase();
        let y = itemB.attributes.course_name.toLowerCase();
        if (x < y) {
          return -1;
        }
        if (x > y) {
          return 1;
        }
        return 0;
      });

      this.filteredCourses = this.courses;

      const res02 = await API.get(
        `users/${this.currentUser.id}?populate[user_course_completions][populate][0]=course&populate=courses`
      );

      this.userCourses = res02.data.courses;
      this.userCourseCompletions = res02.data.user_course_completions;
      //filter only get the course that is not null.
      this.userCourseCompletions = this.userCourseCompletions.filter(
        (i) => i.course !== null
      );
      this.isUpdating = false;
    }catch(error){
      console.error("An error occurred:", error);
      this.isUpdating = false;
      window.location.reload();
    }

  },
};
</script>
